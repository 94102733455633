import React, { Component } from 'react'



class Addon extends Component {

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
            Paxful Clone Script</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                Affiliate Program
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                Multi-Language Compatibility
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                Gift Card Payment Method
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                API Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                Price Calculator
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Affiliate Program
                      </h4>
                      <p className="pharagraph">Affiliate Programs allow the users to invite friends or crypto traders to your platform providing them a reward for each referral.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1">
                      <img width="320px" height="320px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/affiliate-program.png" alt="Affiliate Program" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Multi Language Support
                      </h4>
                      <p className="pharagraph">Extend the potential of your business flow with the multi-language compatibility of our Paxful clone software that helps you connect with diverse provincial users.
                      </p> </div>
                    <div className="col-md-6 text-center order1">
                      <img width="320px" height="320px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/multi-lang-support.png" alt="Multi Language Support" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Gift Card Payment Method
                      </h4>
                      <p className="pharagraph">Enable your users to buy cryptocurrency with the Gift Card Payment Method that revolutionizes the way of trading similar to fiat to crypto transactions.
                      </p> </div>
                    <div className="col-md-6 text-center order1">
                      <img width="320px" height="320px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/gift-card-payment-system.png" alt="Gift Card Payment Method" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">API Integration</h4>
                      <p className="pharagraph">Our Paxful clone exchange aids you in providing feature-loaded P2P trading services through API integrations so that the users experience better convenience.
                      </p> </div>
                    <div className="col-md-6 text-center order1">
                      <img width="461px" height="259px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/api-documentation.png" alt="API Documentation" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Price Calculator
                      </h4>
                      <p className="pharagraph">The price calculator feature helps users to calculate exactly how much global cryptocurrency is worth in the purchase of available investment accurately upon the up-to-date rates.
                      </p> </div>
                    <div className="col-md-6 text-center order1">
                      <img width="421px" height="314px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/crypto-price-calculator.png" alt="Price Calculator" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon