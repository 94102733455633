import React from 'react'

class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our Paxful Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Secured Escrow Service</h4>
                <p className="pharagraph">Allow your users to experience the next level of fund security with a dedicated escrow service that is an integral part of our exchange software like Paxful.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/secured-escrow-service.png" alt="Secured Escrow Service" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/multi-payment-acceptance.png" alt="Multi Payment Processing" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Multi Payment Processing
                </h4>
                <p className="pharagraph">Ensure your users' convenience with a multi-payment processing gateway that allows them to make payments in simple and stable circumstances.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">BUY/SELL Ad posting
                </h4>
                <p className="pharagraph">Our Paxful-like exchange has an easy interface that helps traders customize their preferences in placing buy/sell advertisements to trade cryptocurrencies in a hassle-free manner.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="571px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/create-buy-sell-advertisements.png" alt="BUY/SELL Ad posting" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="561px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/realtime-statistics.png" alt="Real-Time Data" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Real-Time Data
                </h4>
                <p className="pharagraph">Elevate your P2P crypto exchange platform’s credibility with real-time statistics like live crypto prices, market charts, trade volume, and graphical representation.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Membership Levels
                </h4>
                <p className="pharagraph">Our Paxful-like exchange script provides categorized benefits to different membership-level users based on their KYC verification, trade frequency, and trade volume.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="612px" height="334px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/membership-levels.png" alt="Membership Levels" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures