import React from 'react'
import Slider from "react-slick";
import ButtonComponent from '../ButtonComponent/ButtonComponent'



class Banner extends React.Component {

  state = { 
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">Paxful</span> Clone Script</h1>
                <p className='sub-heading'>To Start A P2P Crypto Exchange Like Paxful!</p>
                <p className="pharagraph">Leverage your crypto business with our Paxful-like exchange software that is sincerely curated by our developers using cutting-edge technologies. Our Paxful Clone Script comes with customizable security protocols that help to trade crypto assets for peer-to-peer crypto transactions.
                </p>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
              <div className="screen" >
                <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png" alt="paxful clone laptop front view" />
                <div className="outder">
                <Slider {...settings}>
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/paxful-clone-script.png" alt="paxful clone script slide1" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/paxful-clone.png" alt="paxful clone script slide2" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/paxful.png" alt="paxful clone script slide3" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner