import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span> Us For Paxful Clone Script?</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img width="491px" height="465px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/why-choose-paxful.png" alt="Why Choose us for Paxful Clone Script Image1" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is the best <span class="learn-more"><a href='https://www.coinsclone.com/cryptocurrency-exchange-development-company/'>cryptocurrency exchange development company</a></span> specializing in providing crypto trading platform scripts and white-label solutions with ideal trading modules. Being a pioneer in the crypto space, our in-house team of blockchain developers develops exchanges that meet your business goals perfectly with premium outcomes. Our P2P Bitcoin exchange platform is crafted with future-friendly technologies to simplify the hassles in business upgrades. With greater technical competence from 100+ crypto projects, our experts are well-trained to customize the turnkey Paxful clone software into a thriving P2P exchange business as per your expectations.
              </p>
              <p className="pharagraph">Stay ahead of the business competition with our pool of expert developers, who can transform your visionary goals into a live business model conferring the state-of-the-art P2P trading platform empowered by escrow and higher-end features. Get in touch with our business team to create a P2P crypto exchange platform similar to Paxful efficiently.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose