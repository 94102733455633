import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'



const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3 bluecolor">How does our</span> Paxful Clone work?</h2>
        </div>
        { isDesktop && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/paxful-new/paxful-workflow.webp" 
              alt="Working flow of our Paxful clone software"
              width={1100} />
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/paxful-mobile.webp" 
              alt="Working flow of our Paxful clone software"
              width={392} />
              )}
      </div>
    </section>
  )
}

export default HowOur