import React from "react"

class RevenueMod extends React.Component {

  render() {
    return (

      <section className="benifor pax pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="heading-h3"><span className="bluecolor">Revenue Modules </span>in our Paxful Clone Script</span>
            </h2>
            <p className="text-center">
            Our custom Paxful clone software has diversified profit possibilities to harness the best profit potential from the P2P crypto exchange business.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Trading Fees</h3>
                <p className="pharagraph">
                Trading Fees are predefined fees charged for every transaction processed over the peer-to-peer exchange. This fee is a major source of income that would be in fixed term, typically in a certain percentage of total trade volume.
                </p>
              </div>
              <div className="square" >
                <h3>Listing Fees</h3>
                <p className="pharagraph">
                Listing Fees are another source of income set for listing a new crypto asset in your P2P exchange platform. The platform will decide the listing fee range considering market data and it usually varies from platform to platform.
                </p>
              </div>
              <div className="square" >
                <h3>Withdrawal Fees</h3>
                <p className="pharagraph">
                Withdrawal Fees are the charges the user has to pay the P2P exchange platform for initiating withdrawals. Withdrawal fees are also charged in some percentage of the total value of assets withdrawn.
                </p>
              </div>
            </div>
            <div className="d-lg-flex flex-wrap add-two">
              <div className="square" >
                <h3>Advertising fee</h3>
                <p className="pharagraph">
                Once your platform grabs attention and the viewership gets increased, you can get revenue through advertising fees. This fee is paid by entrepreneurs who list advertisements on the most popular exchange.
                </p>
              </div>
              <div className="square" >
                <h3>Membership fee</h3>
                <p className="pharagraph">
                The platform additionally collects a membership fee from the users who are looking to access the premium services from the exchange. The membership fee is paid by users depending upon the service duration, ie weekly, monthly, or annually.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default RevenueMod
