import React from 'react'


class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg transp'>
       <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className='bluecolor'>White-Label</span> Paxful Clone Script</h2>
            </div>
          </div>

          <div className="text-center">
            <p className="pharagraph">Paxful clone script is a ready-made P2P crypto exchange software replicating the functionalities of Paxful, a popular P2P crypto exchange platform. Our Paxful Exchange software replicates all the original features and add-on modules of the existing Paxful platform that adds uniqueness to your peer-to-peer exchange. Our industry’s best Paxful clone is completely customizable and authenticated for security and legal obligations. Hence your crypto exchange will be secured from cyberbullying and malware activities. 
            </p>
            <p className="pharagraph mb-0">We have an innovative white-label Paxful Clone script that resembles Paxful Exchange to enhance the user experience of your exchange platform. Additionally, we help you initiate your peer-to-peer cryptocurrency exchange business instantly with our white-label Paxful clone script.
            </p>
          </div>
        </div>
      </div>
      </div>
    </section>
    ) 
  }
}

export default Whatis