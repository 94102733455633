import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class CaseStudy extends React.Component {

  render() {

    return (
      <section className="banner casestudy pt-0">  
        <div className='casestudy-bg'>
        <div className="container">
            <h2 className='heading-h2 text-center'>Our Recent <span className='bluecolor'>successful Work</span></h2>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/paxful-new/savita-logo.webp"
                    alt="Savita Logo"
                    width={300}
                    className='mb-4'
                />
                <ul>
                    <li>Savita, a Centralized Crypto Exchange now approached us to develop a peer-to-peer exchange with <b>IEO Launchpad and lending modules</b>.</li>
                    <li>Our Paxful Clone script ensured top security with <b>high transaction speed</b> which eventually resulted in more than <b>21K active users</b>.</li>
                    <li>Savita soon became the trader’s favorite place with more than <b>347K trading volume</b> recorded in just <b>24 hours</b>.</li>
                    <li>With exchange apps for Android and IOS, Savita has achieved a remarkable profit of nearly $76 million with the help of our Paxful exchange clone script.</li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/paxful-new/savita.webp"
                alt="savita"
                width={780}
              />
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Get a free demo of our Paxful clone script</a>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default CaseStudy