import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class OurAndroid extends React.Component {

  render() {
    return (
      <section className="android paxand pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            <h3 className="heading-h2"><span className="heading-h3">Paxful Clone App Development For </span><span className="bluecolor">Android & iOS</span></h3>
          </div>

          <div className="row table-content orderflex mobilewhitebox">
            <div className="col-md-12 col-lg-7 order2 mb-0">
              <p className="pharagraph">Our Paxful clone app comes with enhanced and agile infrastructure to offer lightning-fast speed and mind-blowing performance, designed to offer you top-notch p2p crypto trading. With all security plug-ins and industry-leading features, our Paxful clone app will keep the promise of your branding and business values. As a renowned cryptocurrency exchange clone script provider, We ensure the best-in-class paxful clone app development with eye-catching features that cater to the needs of diverse grade traders.
              </p>
              <p className="pharagraph">Keeping compatibility and convenience as a top priority, our mobile application like Paxful has designated functionality for both Android and iOS platforms. With push notification and support systems, maintaining user relationships and sending 24/7 upgrades is possible with our mobile app to trade in a trouble-free manner.
              </p>
              <div className='mobile-hide'>
              <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img width="530px" height="650px" src="https://coinsclone.mo.cloudinary.net/images/paxful/paxful-clone-app-development.png" alt="Paxful Clone App Development" />
            </div>
          </div>
          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>
          <div className='mobile-on'>
            <ButtonComponent />
          </div>
        </div>
      </section>
    )
  }
}

export default OurAndroid