import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of our</span>
              Paxful Clone Script</h3>
            <p className="pharagraph head">Our development approach process follows the below-mentioned essential procedures for developing a function-rich p2p Exchange.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/requirment-and-planning.png" alt="Development and Approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirement Gathering</h5>
            <p className="pharagraph" >Our team will collect all the requirements and provide you the best solution for your crypto business.
            </p>
            <br />
            <h5 >Precise Planning </h5>
            <p className="pharagraph" >Our business team experts will first analyze your business requirements which will be highly confidential and protected. As per your business needs, our experts design a plan for developing a platform like Paxful.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p className="pharagraph" >Designing the perfect architecture is more important. Our UI/UX designers will sketch your platform as per your taste.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">Our well-skilled developers use only advanced technologies to be equipped with high security, smart contacts, and necessary trading features.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/design-development.png" alt="Designing and development" />
          </div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/testing-and-deployment.png" alt="Testing and deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Testing</h5>
            <p  className="pharagraph">We will run the beta test in our paxful clone which will be helpful for rectifying the errors, issues, or bugs.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph mb-0">The product we build will be delivered with all important functionalities and now you can launch your platform in the crypto market to reap high profits
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach