import React, { Component } from 'react'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
              Our Paxful Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')} >
                  Informative User Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')}>
                  Strategic Admin Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  KYC/AML
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  User Reviews
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Wallet Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Affiliate Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Vendor Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Trusted & Blocked Users
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Withdrawal Limits
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  User Chat System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab11')} >
                  Customer Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab12')} >
                  Admin Profits
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Informative User Dashboard
                        </h4>
                        <p className="pharagraph">In our P2P exchange software like Paxful, we provide an informative user dashboard that allows users to view their wallet portfolio, multiple payment options, account activities, transaction history, completed trades, and Buy/Sell advertisements.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="430px" height="280px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/classic-user-dashboard.png" alt="Informative User Dashboard" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Strategic Admin Dashboard
                        </h4>
                        <p className="pharagraph">Our skilled developers design a phenomenal dashboard that helps the platform owner (admin) view the total users, KYC-verified users, pending withdrawal requests, and completed trades with a clear representation of statistical data.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="440px" height="290px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/powerful-admin-dashboard.png" alt="Powerful Admin Dashboard" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">KYC/AML
                        </h4>
                        <p className="pharagraph">The dedicated KYC/AML module takes data security to the next level as it allows verified persons to buy and sell cryptocurrencies. Also, our P2P exchange software like Paxful will ensure to building of trust among the users. 
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="469px" height="283px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/kyc-aml.png" alt="KYC/AML" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">User Reviews
                        </h4>
                        <p className="pharagraph">With this feature, users or traders can post their reviews and give their ratings regarding the platform's performance, trading features, user experience, and suggestions in a transparent manner.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="251px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/Rreviews-and-ratings.png" alt="User Reviews" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2"> 
                      <div className="mw466">
                        <h4 className="heading-h4">Wallet Integration
                        </h4>
                        <p className="pharagraph">Our Paxful-like platform includes various wallets integration features that are highly beneficial for traders who prefer both crypto and fiat transactions across different wallets. Users can easily connect their wallets and bank accounts through a simple API.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/fiat-wallet-integration.png" alt="Wallet Integration" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Affiliate Dashboard
                        </h4>
                        <p className="pharagraph">With an affiliate dashboard, users can check the status of their referral links and earning possibilities clearly with the source of information. The platform will have different affiliate levels and offer benefits accordingly.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw350" width="452px" height="308px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/affiliate-dashboard.png" alt="Affiliate Dashboard" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Vendor Dashboard
                        </h4>
                        <p className="pharagraph">The vendor dashboard is a feature useful for vendors to oversee their investment, trade balance, monthly trade volumes, closing ratio, and previous successful trades with detailed data interpretation.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/vendor-dashboard.png" alt="Vendor Dashboard" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Trusted & Blocked Users
                        </h4>
                        <p className="pharagraph">Trusting users is a good/bad practice to stay in touch with buyers/sellers with whom you have had positive or negative experiences. Their offers will then be prioritized or won’t be shown to them on the offer page based on their activities.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/trusted-blocked-users.png" alt="Trusted & Blocked Users" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Withdrawal Limits
                        </h4>
                        <p className="pharagraph">Our exchange software allows the admin to set monthly withdrawal limits and the users to oversee their withdrawal transactions quickly. This will help the platform admin to handle the available funds effectively.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/withdrawal-limits.png" alt="Withdrawal Limits" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466"> 
                        <h4 className="heading-h4">User Chat System
                        </h4>
                        <p className="pharagraph">This is an innovative messaging system that enables both trading parties to initiate communication between them regarding trades, sharing proof of transactions, acknowledging orders, and more.</p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/user-messaging-system.png" alt="User Messaging System" />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Customer Support
                        </h4>
                        <p className="pharagraph">Our readymade software has an instant dispute management system along with chat support. The traders can raise their queries/concerns to customer support with these two options and they help you keep the user relationship.</p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="379px" height="280px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/support-system.png" alt="Customer Support" />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Profits
                        </h4>
                        <p className="pharagraph">With our readymade software similar to Paxful, the admin has the option to set up their profit percentage by regulating the platform fee structure using the admin profit management portal.</p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                      <img width="384px" height="287px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/admin-profits.png" alt="Admin Profits" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures